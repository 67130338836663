// extracted by mini-css-extract-plugin
export var darkButton = "sign-in-module--darkButton--673a5";
export var headingLarge3xl = "sign-in-module--headingLarge3xl--ddcca";
export var heroContent = "sign-in-module--heroContent--cd5d4";
export var heroText = "sign-in-module--heroText--593a2";
export var herobackgroundIcon = "sign-in-module--herobackgroundIcon--8aad6";
export var herosection = "sign-in-module--herosection--a249f";
export var input = "sign-in-module--input--4cb24";
export var inputs = "sign-in-module--inputs--447e5";
export var label = "sign-in-module--label--8bcb2";
export var overlay = "sign-in-module--overlay--46e92";
export var sectionText = "sign-in-module--sectionText--d33fb";
export var usernameOrEmail = "sign-in-module--usernameOrEmail--30559";