import * as React from 'react';

import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import SignInPage from '../components/Login/sign-in-page';
import UnAuthContent from '../components/Login/UnAuthContent';

const SignIn = () => {
	return (
		<>
			<Layout>
				<UnAuthContent>
					<SignInPage />
				</UnAuthContent>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Doctormedica.co | Sign In'
		description='Sign in with DoctorMedica.co and start saving on medical devices, aesthetics accessories and more.'
	/>
);

export default SignIn;
